import i18next from 'i18next';

import { Resources } from './addTranslations.types';

const addTranslations = (ns: string, resources: Resources) => {
  if (!i18next?.addResourceBundle) {
    return console.info(
      `i18next is not initialized! Couldn't add "${ns}" namespace. In root of front application (before adding translations) call initI18n()`,
    );
  }

  Object.entries(resources).forEach(([lng, translations]) =>
    i18next.addResourceBundle(lng, ns, translations, true, false),
  );
};

export default addTranslations;
