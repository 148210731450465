import { initReactI18next } from 'react-i18next';

import i18next, { Resource } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { mergeDeepLeft } from 'ramda';

import { InitI18nOptions } from './initI18n.types';

export default function initI18n(
  resources: Resource,
  options: Partial<InitI18nOptions> = {},
  useDetector = false,
) {
  const langs = Object.keys(resources) as string[];

  const opts: InitI18nOptions = {
    lng: langs[0],
    fallbackLng: langs[0],
    supportedLngs: langs,
    defaultNS: 'translation',
    debug: process.env['DEBUG'] === 'true',
    interpolation: undefined,
  };

  const { lng, fallbackLng, supportedLngs, defaultNS, debug, interpolation } =
    mergeDeepLeft(options, opts) as InitI18nOptions;

  if (useDetector) {
    i18next
      .use(detector)
      .use(initReactI18next)
      .init({
        lng: undefined,
        fallbackLng,
        supportedLngs,
        debug,
        defaultNS,
        resources,
        partialBundledLanguages: true,
        nsSeparator: false,
        interpolation,

        detection: {
          order: [
            'querystring',
            'cookie',
            // 'localStorage',
            'sessionStorage',
            'navigator',
            'htmlTag',
          ],
          lookupQuerystring: 'lng',
          lookupCookie: 'i18next',
          lookupLocalStorage: 'i18nextLng',
          lookupSessionStorage: 'i18nextLng',
          // cache user language
          caches: [],
          excludeCacheFor: ['cimode'],
          convertDetectedLanguage: (lng?: string) => lng?.split('-')[0] || 'de',
        },
      });
    return;
  }

  i18next.use(initReactI18next).init({
    lng,
    fallbackLng,
    supportedLngs,
    debug,
    defaultNS,
    resources,
    partialBundledLanguages: true,
    nsSeparator: false,
    interpolation,
  });
}
