import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { ThemeProvider } from '@bluecodecom/theme';

import './i18n';

const App = lazy(() => import('./app'));

createRoot(document.getElementById('root') as HTMLElement).render(
  <LoaderProvider>
    <ThemeProvider observe>
      <App />
    </ThemeProvider>
  </LoaderProvider>,
);
