import { useContext } from 'react';

import { ThemeContext } from '../../providers/Theme';

const useThemeContext = () => {
  const ctx = useContext(ThemeContext);

  if (!ctx) {
    throw new Error('useThemeContext must be used inside ThemeContext');
  }

  return ctx;
};

export default useThemeContext;
