import i18next from 'i18next';

const updateTranslations = (
  translations: object,
  ns = 'translation',
  language = '',
) => {
  const currentLanguage = i18next.language;

  i18next.addResourceBundle(
    language || currentLanguage,
    ns,
    translations,
    true,
    true,
  );

  return i18next.changeLanguage(currentLanguage);
};

export default updateTranslations;
